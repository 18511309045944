import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

import * as XLSX from "xlsx";

const UltimasMovimentacoes = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requisicao = new Api();
  const notify = useNotification();

  const [movimentacoes, setMovimentacoes] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const exportToExcel = () => {
    const colunasDesejadas = [
        { key: 'id', header: 'ID' },
        { key: 'numeroProtocolo', header: 'Número do Protocolo' },
        { key: 'etapaIphan', header: 'Etapa IPHAN' },
        { key: 'responsavelLegal', header: 'Responsável Legal' },
        { key: 'cpf', header: 'CPF' },
        { key: 'razaoSocial', header: 'Razão Social' },
        { key: 'cnpj', header: 'CNPJ' },
        { key: 'enderecoComercial', header: 'Endereço Comercial' },
        { key: 'enderecoCorrespondencia', header: 'Endereço de Correspondência' },
        { key: 'enderecoEletronico', header: 'Endereço Eletrônico' },
        { key: 'nomeEmpreendimento', header: 'Nome do Empreendimento' },
        { key: 'tipologia', header: 'Tipologia' },
        { key: 'setor', header: 'Setor' },
        { key: 'detalhamento', header: 'Detalhamento' },
        { key: 'subDetalhamento', header: 'Subdetalhamento' },
        { key: 'dataMovimentacaoFormatada', header: 'Data de Movimentação' },
        { key: 'telefone', header: 'Telefone' },
        { key: 'municipio', header: 'Município' },
        { key: 'estado', header: 'Estado' },
        { key: 'enquadramento', header: 'Enquadramento TRE' }
    ];

    const dadosFiltrados = movimentacoes.map(obj => {
        const novoObj = {};
        colunasDesejadas.forEach(coluna => {
            if (Object.prototype.hasOwnProperty.call(obj, coluna.key)) {
                novoObj[coluna.header] = obj[coluna.key];
            }
        });
        return novoObj;
    });

    const ws = XLSX.utils.json_to_sheet(dadosFiltrados);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Movimentações");

    XLSX.writeFile(wb, "movimentacoes.xlsx");
};


  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setIsDialogVisible(true);
  };

  useEffect(() => {
    const fetchMovimentacoes = async () => {
      try {
        setLoading(true);
        const resposta = await Requisicao.Get({
          endpoint: "/ProcessoIphan/todos",
          config: auth.GetHeaders()
        });
        if (resposta && Array.isArray(resposta)) {
          setMovimentacoes(
            resposta.map((mov) => ({
              ...mov,
              dataMovimentacaoFormatada: MaskUtil.applyDataAndHoraMask(
                mov.dataMovimentacao
              )
            }))
          );
        }
      } catch (error) {
        console.error(error);
        notify({
          type: "error",
          message: "Erro ao buscar as últimas movimentações."
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMovimentacoes();
  }, [setLoading, auth, notify]);

  const baseUrl = "https://sei.iphan.gov.br/sei/modulos/pesquisa/";

  return (
    <div>
      <div className="w-100 d-flex flex-row mb-3">
        <Button
          label="Exportar para Excel"
          onClick={exportToExcel}
          className="btn btn-primary"
        />
      </div>
      <div className="card p-3">
        <DataTable value={movimentacoes} paginator rows={10}>
          <Column
            field="dataMovimentacaoFormatada"
            header="Data de Inserção"
            filter
            sortable
          />
          <Column
            field="numeroProtocolo"
            header="Número do Protocolo"
            filter
            sortable
          />
          <Column field="etapaIphan" header="Etapa IPHAN" filter sortable />
          <Column
            field="linkArquivo"
            header="Link do Arquivo"
            body={(rowData) =>
              rowData.linkArquivo && (
                <Link
                  to={`${baseUrl}${rowData.linkArquivo}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir
                </Link>
              )
            }
          />
          <Column
            field="linkPagina"
            header="Link da Página"
            body={(rowData) =>
              rowData.linkPagina && (
                <Link
                  to={`${baseUrl}${rowData.linkPagina}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visitar
                </Link>
              )
            }
          />
          <Column
            field="statusProcessamento"
            header="Status do Processamento"
            filter
            sortable
          />
          <Column body={(rowData) => <Button label="Detalhes" onClick={() => openModal(rowData)} />}/>
        </DataTable>
      </div>
      <Dialog
        header="Detalhes da Movimentação"
        visible={isDialogVisible}
        style={{ width: "50vw" }}
        modal
        onHide={() => setIsDialogVisible(false)}
      >
        {selectedRowData && (
          <div className="row">
            <div className="col-12">
              <h3>Informações Gerais</h3>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-user" /> Responsável Legal: <strong>{selectedRowData.responsavelLegal}</strong>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-id-card" /> CPF: <strong>{selectedRowData.cpf}</strong>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-briefcase" /> Razão Social: <strong>{selectedRowData.razaoSocial}</strong>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-users" /> CNPJ: <strong>{selectedRowData.cnpj}</strong>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-phone" /> Telefone: <strong>{selectedRowData.telefone}</strong>
            </div>
            <div className="col-12 col-md-6">
              <i className="pi pi-map-marker" /> Município: <strong>{selectedRowData.municipio}</strong>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <i className="pi pi-globe" /> Estado: <strong>{selectedRowData.estado}</strong>
            </div>

            <div className="col-12 mt-3">
              <h3>Endereçamento</h3>
            </div>
            <div className="col-12">
              <i className="pi pi-map-marker" /> Endereço Comercial:{" "}
              <strong>{selectedRowData.enderecoComercial}</strong>
            </div>
            <div className="col-12">
              <i className="pi pi-envelope" /> Endereço de Correspondência:{" "}
              <strong>{selectedRowData.enderecoCorrespondencia}</strong>
            </div>
            <div className="col-12 mb-3">
              <i className="pi pi-inbox" /> Endereço Eletrônico:{" "}
              <strong>{selectedRowData.enderecoEletronico}</strong>
            </div>

            <div className="col-12">
              <h3>Detalhes do Empreendimento</h3>
            </div>
            <div className="col-12">
              <i className="pi pi-info-circle" /> Nome do Empreendimento:{" "}
              <strong>{selectedRowData.nomeEmpreendimento}</strong>
            </div>
            <div className="col-12">
              <i className="pi pi-sitemap" /> Tipologia:{" "}
              <strong>{selectedRowData.tipologia}</strong>
            </div>
            <div className="col-12">
              <i className="pi pi-chart-line" /> Setor:{" "}
              <strong>{selectedRowData.setor}</strong>
            </div>
            <div className="col-12">
              <p className="m-0">
                <i className="pi pi-search" /> Detalhamento:{" "}
                <b>
                  {selectedRowData.detalhamento &&
                    selectedRowData.detalhamento.replace(",", ", ")}
                </b>
              </p>
            </div>
            <div className="col-12">
              <p className="m-0">
                <i className="pi pi-search-plus" /> Subdetalhamento:{" "}
                <b>
                  {selectedRowData.subDetalhamento &&
                    selectedRowData.subDetalhamento.replace(",", ", ")}
                </b>
              </p>
            </div>
            <div className="col-12">
              <i className="pi pi-calendar" /> Data Movimentação FCA:{" "}
              <strong>{selectedRowData.dataMovimentacaoFCA ? new Date(selectedRowData.dataMovimentacaoFCA).toLocaleDateString('pt-BR') : ''}</strong>
            </div>
            <div className="col-12">
              <i className="pi pi-calendar" /> Data Movimentação TRE:{" "}
              <strong>{selectedRowData.dataMovimentacaoTRE ? new Date(selectedRowData.dataMovimentacaoTRE).toLocaleDateString('pt-BR') : ''}</strong>
            </div>
            <div className="col-12">
              <i className="pi pi-tag" /> Enquadramento TRE:{" "}
              <strong>{selectedRowData.enquadramento}</strong>
            </div>

          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UltimasMovimentacoes;
