/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar } from "primereact/avatar";
// import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";

import { useAuth } from "context/AuthContext";

import IconUser from "../../assets/icons/l-1.jpg";
import Logo from "../../assets/img/logo/black-full.63543a82.png";
import "./styled.css";

const Header = () => {
  const auth = useAuth();
  const op = useRef(null);
  const navigate = useNavigate();

  const [Nome, setNome] = useState("Nome");

  useEffect(() => {
    if (auth.isAuthenticated()) setNome(auth.user.nome);
  }, [auth]);

  return (
    <header className="header-container">
      <div className="header-box">
        <div className="header-item-center">
          <img src={Logo} alt="Logo da Empresa" style={{ height: "40px" }} />
        </div>
        <div className="header-item-right">
          <div className="header-box-item-menu">
            <button type="button" className="button-none">
              <Avatar
                className="p-overlay-badge"
                icon="pi pi-bell"
                shape="circle"
                style={{ backgroundColor: "#0000000", color: "#000000" }}
              >
                {/* <Badge value="4" severity="danger" /> */}
              </Avatar>
            </button>
          </div>
          <button
            type="button"
            className="button-none header-button-user"
            onClick={(e) => op.current.toggle(e)}
          >
            <span>{Nome}</span>
            <span>
              <img className="header-button-img" src={IconUser} alt="user" />
            </span>
          </button>
          <OverlayPanel ref={op}>
            <button
              className="btn"
              type="button"
              onClick={() => {
                navigate("/");
                auth.logout();
              }}
            >
              Sair
            </button>
          </OverlayPanel>
        </div>
      </div>
    </header>
  );
};

export default Header;
