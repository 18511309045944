import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet
} from "react-router-dom";

import Layout from "components/Layout";

import { NotificationProvider } from "context/NotificationContext";

import FCA from "pages/FCA";
import Graficos from "pages/graficos";
import Home from "pages/home";
import Login from "pages/login";
import UltimasMovimentacoes from "pages/ultimasMovimentacoes";

import PrivateRoute from "./modules/PrivateRoute";

const MainRouter = () => {
  const routeConfig = createBrowserRouter([
    { path: "/", element: <Navigate to="/login" /> },
    { path: "/login", element: <Login /> },
    {
      path: "/app",
      element: <PrivateRoute element={Outlet} />,
      children: [
        {
          index: true,
          element: (
            <Layout NomePaginaAtual="Home">
              <Home />
            </Layout>
          )
        },
        {
          path: "ultimasMovimentacoes",
          element: (
            <Layout NomePaginaAtual="Últimas Movimentações">
              <UltimasMovimentacoes />
            </Layout>
          )
        },
        {
          path: "FCA",
          element: (
            <Layout NomePaginaAtual="FCA">
              <FCA />
            </Layout>
          )
        },
        {
          path: "paines",
          element: (
            <Layout NomePaginaAtual="Graficos">
              <Graficos />
            </Layout>
          )
        }
      ]
    }
  ]);

  return (
    <NotificationProvider>
      <RouterProvider router={routeConfig} />
    </NotificationProvider>
  );
};

export default MainRouter;
