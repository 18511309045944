import { AuthProvider } from "context/AuthContext";

import MainRouter from "router";
import ErrorBoundary from "router/modules/ErrorBoundary";

import "assets/styles/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "assets/styles/Restyling.scss";
import "assets/styles/App.css";

const App = () => (
  <AuthProvider>
    <ErrorBoundary>
      <MainRouter />
    </ErrorBoundary>
  </AuthProvider>
);

export default App;
