import { useEffect, useRef, useState } from "react";

import BigNumber from "components/BigNumber";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

import Chart from "chart.js/auto";

const ChartComponent = ({ type, data, options }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const createChart = () => {
      chartInstance.current = new Chart(chartContainer.current, {
        type,
        data,
        options
      });
    };

    createChart();

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [type, data, options]);

  return <canvas ref={chartContainer} />;
};

const BarChart = ({ data, options }) => (
  <ChartComponent type="bar" data={data} options={options} />
);

const PieChart = ({ data, options }) => (
  <ChartComponent type="pie" data={data} options={options} />
);

const LineChart = ({ data, options }) => (
  <ChartComponent type="line" data={data} options={options} />
);

const Graficos = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requisicao = new Api();
  const notify = useNotification();

  const alturaGraficos = 500;
  const maxCaraquiter = 25;

  const [QtdProcessando, setQtdProcessando] = useState(0);
  const [PorcentagemProcessando, setPorcentagemProcessando] = useState(0);
  const [QtdFinalizado, setQtdFinalizado] = useState(0);
  const [PorcentagemFinalizado, setPorcentagemFinalizado] = useState(0);
  const [QtdErro, setQtdErro] = useState(0);
  const [PorcentagemErro, setPorcentagemErro] = useState(0);

  const [ChartColunas, setChartColunas] = useState([]);
  const [Estados, setEstados] = useState([]);
  const [ChartLinhas, setChartLinhas] = useState({});

  useEffect(() => {
    // Função para obter a data de inserção sem a hora
    function ExtrairDataSemHora(objeto) {
      return objeto.dataMovimentacao.split("T")[0];
    }

    // Função para contar objetos com base na data de inserção sem a hora
    function ContarObjetosPorData(objetos, data) {
      return objetos.filter((objeto) => ExtrairDataSemHora(objeto) === data)
        .length;
    }

    function GetTop10RazaoSocial(lista) {
      try {
        // Objeto para armazenar a contagem de cada razão social
        const contagem = {};

        // Contar as razões sociais
        lista.forEach((item) => {
          contagem[item.razaoSocial] = (contagem[item.razaoSocial] || 0) + 1;
        });

        // Converter o objeto em uma matriz de pares [razaoSocial, contagem]
        const contagemArray = Object.entries(contagem);

        // Ordenar a matriz de acordo com as contagens em ordem decrescente
        contagemArray.sort((a, b) => b[1] - a[1]);

        // Pegar as 10 primeiras razões sociais mais frequentes
        const top10 = contagemArray.slice(0, 10);

        return top10;
      } catch (error) {
        console.error(error);
      }
    }

    function GetEstados(lista) {
      try {
        // Objeto para armazenar a contagem de cada razão social
        const contagem = {};

        // Contar as razões sociais
        lista.forEach((item) => {
          contagem[item.setor] = (contagem[item.setor] || 0) + 1;
        });

        // Converter o objeto em uma matriz de pares [razaoSocial, contagem]
        const contagemArray = Object.entries(contagem);

        // Ordenar a matriz de acordo com as contagens em ordem decrescente
        contagemArray.sort((a, b) => b[1] - a[1]);

        return contagemArray;
      } catch (error) {
        console.error(error);
      }
    }

    const FetchMovimentacoes = async () => {
      try {
        setLoading(true);
        const resposta = await Requisicao.Get({
          endpoint: "/ProcessoIphan/todos",
          config: auth.GetHeaders()
        });

        const objetosProcessando = resposta.filter(
          (objeto) => objeto.statusProcessamento === "processar"
        );
        const objetosFinalizado = resposta.filter(
          (objeto) => objeto.statusProcessamento === "Finalizado"
        );
        const objetosErro = resposta.filter((objeto) =>
          objeto.statusProcessamento.includes("Erro")
        );

        setQtdProcessando(objetosProcessando.length);
        setPorcentagemProcessando(
          (objetosProcessando.length / resposta.length) * 100
        );
        setQtdFinalizado(objetosFinalizado.length);
        setPorcentagemFinalizado(
          (objetosFinalizado.length / resposta.length) * 100
        );
        setQtdErro(objetosErro.length);
        setPorcentagemErro((objetosErro.length / resposta.length) * 100);

        const top = GetTop10RazaoSocial(resposta).map((item) => ({
          data: [item[1]],
          label:
            item[0].length > maxCaraquiter
              ? `${item[0].slice(0, maxCaraquiter)}...`
              : item[0]
        }));

        setChartColunas(top);

        const estadso = GetEstados(resposta).map((item) => ({
          data: item[1],
          label: item[0]
        }));

        setEstados(estadso);

        // Obter uma lista de datas únicas
        const datasUnicas = Array.from(
          new Set(resposta.map((objeto) => ExtrairDataSemHora(objeto)))
        );
        // Obtendo a quantidade de objetos por data
        const quantidadePorData = datasUnicas.map((data) =>
          ContarObjetosPorData(resposta, data)
        );

        setChartLinhas({ data: quantidadePorData, labels: datasUnicas });
      } catch (error) {
        console.error(error);
        notify({
          type: "error",
          message: "Erro ao buscar as últimas movimentações."
        });
      } finally {
        setLoading(false);
      }
    };

    if (auth.isAuthenticated()) FetchMovimentacoes();
  }, [auth]);

  const barData = {
    labels: ["Tope 10"],
    datasets: ChartColunas
  };

  const pieData = {
    labels: Estados.map((item) => item.label),
    datasets: [
      {
        data: Estados.map((item) => item.data)
      }
    ]
  };

  const lineData = {
    labels: ChartLinhas.labels
      ? ChartLinhas.labels.map((item) => MaskUtil.applyDataMask(item))
      : [],
    datasets: [
      {
        label: "Protocolos Envidados",
        data: ChartLinhas.data
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  const data = [
    {
      nome: "Quantidade de Protocolos em Processamento",
      valor: QtdProcessando,
      porcentagem: PorcentagemProcessando,
      icone: "bi bi-box",
      cores: {
        icone: "#fff",
        background_icone: "#0068ff",
        background: "#fff"
      }
    },
    {
      nome: "quantidade de Protocolos Finalizados",
      valor: QtdFinalizado,
      porcentagem: PorcentagemFinalizado,
      icone: "bi bi-box",
      cores: {
        icone: "#fff",
        background_icone: "#0068ff",
        background: "#fff"
      }
    },
    {
      nome: "quantidade de Protocolos com Falha",
      valor: QtdErro,
      porcentagem: PorcentagemErro,
      icone: "bi bi-box",
      cores: {
        icone: "#fff",
        background_icone: "#0068ff",
        background: "#fff"
      }
    }
  ];

  return (
    <div className="row g-5">
      <div className="col-12">
        <div className="d-flex gap-3">
          {data.map((item) => (
            <BigNumber data={item} />
          ))}
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="w-100 d-flex flex-column align-items-center">
          <h2>Gráfico de série</h2>
          <div className="w-100" style={{ height: `${alturaGraficos}px` }}>
            <BarChart
              data={barData}
              options={{ ...options, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="w-100 d-flex flex-column align-items-center">
          <h2>Line Chart</h2>
          <div className="w-100" style={{ height: `${alturaGraficos}px` }}>
            <LineChart
              data={lineData}
              options={{ ...options, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12">
        <div className="d-flex flex-column align-items-center">
          <h2>Estados</h2>
          <div
            className="d-flex flex-column align-items-center"
            style={{ height: `${alturaGraficos}px`, width: "100%" }}
          >
            <PieChart data={pieData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graficos;
