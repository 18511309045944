import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useAuth } from "context/AuthContext";

import Error401 from "./Error401";

const PrivateRoute = ({ element: Element, requiredAccessLevel }) => {
  const auth = useAuth();

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      const thisAuthenticated = await auth.isAuthenticated();
      setIsAuthenticated(thisAuthenticated);
    };
    checkAuthentication();
  }, [auth]);

  if (isAuthenticated === null) {
    // Aguarde a conclusão da verificação de autenticação
    return null;
  }

  if (requiredAccessLevel.length > 0) {
    if (!requiredAccessLevel.includes(auth.GetAccessLevel())) {
      return <Error401 />;
    }
  }

  return isAuthenticated ? <Element /> : <Error401 />;
};

PrivateRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  requiredAccessLevel: PropTypes.arrayOf(PropTypes.string)
};

PrivateRoute.defaultProps = {
  requiredAccessLevel: []
};

export default PrivateRoute;
