import { useState } from "react";

import PropTypes from "prop-types";

import { LoadingProvider } from "context/LoadingContext";

import BreadCrumb from "../BreadCrumb";
import Header from "../Header";
import Sidebar from "../Sidebar";

const Layout = ({ children, NomePaginaAtual }) => {
  const [ShowMenu, setShowMenu] = useState(false);

  return (
    <>
      <Header />
      <div className="layout-content">
        <div className="main-container">
          <Sidebar Show={ShowMenu} setShow={setShowMenu} />
          <div
            className="main-box"
            style={ShowMenu ? { marginLeft: "229px" } : {}}
          >
            <main className="content">
              <LoadingProvider>
                <BreadCrumb NomePaginaAtual={NomePaginaAtual} />
                {children}
              </LoadingProvider>
            </main>
            <footer>
              <hr />
              <p>Akkadian Tech 2024</p>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  NomePaginaAtual: PropTypes.string.isRequired
};

Layout.defaultProps = {
  children: <div />
};

export default Layout;
