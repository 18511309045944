import React from "react";

import PropTypes from "prop-types";

import "./stylede.scss";

const BigNumber = ({ data }) => (
  <div
    className="big-number"
    style={{ backgroundColor: data.cores.background }}
  >
    <div className="box-1">
      <div
        className="box-icon"
        style={{
          backgroundColor: data.cores.background_icone,
          color: data.cores.icone
        }}
      >
        <i className={data.icone} />
      </div>
      <div className="box-text">
        <h2>{data.valor}</h2>
        <h3>{data.nome}</h3>
      </div>
    </div>
    <div className="box-2">
      <div className="box-porcentagem">
        <div
          className="porcentagem"
          style={{
            width: `${data.porcentagem}%`,
            backgroundColor: data.cores.background_icone
          }}
        />
      </div>
      <p>{data.porcentagem.toFixed(2)}% por cento concluído.</p>
    </div>
  </div>
);

BigNumber.propTypes = {
  data: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    valor: PropTypes.number.isRequired,
    porcentagem: PropTypes.number.isRequired,
    icone: PropTypes.string.isRequired,
    cores: PropTypes.shape({
      icone: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
      background_icone: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default BigNumber;
