import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

import BG from "assets/img/akkadian_fundo.e164cd6d.jpg";
import Logo from "assets/img/logo/black.e9eb3087.png";

import "./styled.css";
import "assets/styles/Form.css";

const Login = () => {
  const auth = useAuth();
  const Requicicao = new Api();
  const navigate = useNavigate();
  const notify = useNotification();

  const [Email, setEmail] = useState("");
  const [Senha, setSenha] = useState("");
  const [Load, setLoad] = useState(false);

  const GoLogin = async (event) => {
    event.preventDefault();
    try {
      setLoad(true);
      const response = await Requicicao.Post({
        endpoint: "/api/Auth/login",
        data: {
          email: Email,
          password: Senha
        }
      });

      if (response != null) {
        auth.login(response); // Usando 'response.data' para acessar os dados da resposta

        navigate("/app"); // Redireciona para "/app" se o login for bem-sucedido
      } else {
        // Trata casos onde o código de status não é 200
        notify({
          type: "erro",
          message:
            "Falha na tentativa de login. Por favor, verifique suas credenciais e tente novamente."
        });
      }
    } catch (error) {
      console.error(error);
      // Trata erros da solicitação, como falhas de rede ou respostas de erro do servidor
    } finally {
      setLoad(false);
    }
  };

  return (
    <div className="login-box-main">
      <div className="login-box-img-bg">
        <img src={BG} alt="bg" />
      </div>
      <div className="logim-card">
        <div className="logim-img-box-card">
          <img src={Logo} alt="logo" />
        </div>
        <form onSubmit={GoLogin} className="logim-form">
          <div className="logim-form-box-text">
            <p>Login</p>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="label-input">
              E-mail
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="senha" className="label-input">
              Senha
            </label>
            <InputText
              id="senha"
              name="senha"
              type="password"
              value={Senha}
              onChange={(e) => setSenha(e.target.value)}
              required
            />
          </div>
          <div className="logim-form-box-btn">
            <Button
              label="Login"
              className="btn-primary btn-big"
              loading={Load}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
